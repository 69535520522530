.App {
  margin:20px 30px;
}

.event-content-body{
  background: #ffdba6;
  border-radius: 50px;
  padding: 30px;
}
.events-logo{
  width: 250px;
  height: 100px;
}

.search-body{
  margin-top: -3rem;
  width: 550px;
  background: #FFFFFF;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.15);
  border-radius: 15px;
  height: 100px;
  border: 1px solid black;
}
.event-cards{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr ;
  column-gap: 2rem;
  row-gap: 2rem;
}

.border-span{
  border: 0.5px solid #CCCCCC;
  height: 2px;
  width: 45%;
  border-radius: 2px ;
}
.load-button{
  padding: 15px 25px;
  width: 143px;
  height: 48px;
  border: 1px solid #CCCCCC;
  border-radius: 35px;
  font-size: 18px;
  font-weight: 400;
  color: black;
  vertical-align: middle;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.load-button:active,
.load-button:focus,
.load-button:focus-within,
input:active,
input:focus,
input:focus-within,
.btn:active,
.btn:focus,
.btn:focus-within{
  outline: none;
  border: 1px solid #CCCCCC;
  box-shadow: none;
}

.card-title{
  font-weight: 600;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}
.font-size-13{
  font-size: 13px;
}
.event-count{
  font-size: 30px;
  font-weight: 700;
  color: #141B25;
  margin-bottom: 1rem;
}
.card-title{
  color: #141B25;
}

input{
  width: 100%;
  height: 50px;
  border: 1px solid #cccccc;
  border-radius: 8px;
}

.btn{
  background: white;
  border: 1px solid #cccccc;
  border-radius: 8px;
}

.dropdown-item{
  border: 1px solid #333333;
}
.dropdown-item:active{
  background: black;
  color: white;
}
.dropdown-menu{
  padding: 0;
  margin: 0;
  border: 0;
}
.dropdown{
  width: 200px;
}
.dropdown-toggle{
  text-align: left;
  width: 200px;
  }
.dropdown-toggle::after{
  position: absolute;
  right: 12px;
  top: 16px;  
}
@media screen and (max-width: 1200px) {
  .event-cards{
    grid-template-columns: 1fr 1fr 1fr ;
  }
}

@media screen and (max-width: 800px) {
  .event-cards{
    grid-template-columns: 1fr 1fr ;
  }
}

/* On screens that are 600px or less, set the background color to olive */
@media screen and (max-width: 400px) {
  .event-cards{
    grid-template-columns: 1fr ;
  }
}

.union-img{
  position: absolute;
  height: 30px;
  left: 10px;
  top: 10px;
}
.card{
  filter: drop-shadow(0px 0px 13.5679px rgba(0, 0, 0, 0.2));
  border-radius: 13.5679px;
}